.popup-error-body .icon-ok-bloc {
  height: 120px;
}

.popup-error-body .icon-ok-bloc .icon-ok {
  width: 60px;
}

.popup-error-body .congratulation-text {
  font-family: Nunito;
  font-size: 30px;
  color: red;
}

.popup-error-body .message-text {
  font-family: Nunito;
  font-size: 17px;
  color: red;
}

.popup-error-body .second-bloc {
  height: 230px;
}

.popup-error-body .btn-ok {
  background-color: red !important;
  border-color: red !important;
}

.popup-error-body .modal-content {
  border-radius: 30px;
}
