.custom-btn-reset-paiement {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 2px #fd9c18 !important;
  font-family: Nunito;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #707070;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fd9c18 !important;
  border-radius: 10px !important;
}

.custom-btn-reset-paiement:hover {
  color: #707070;
}

.custom-btn-reset-paiement:hover,
.custom-btn-reset-paiement:active,
.custom-btn-reset-paiement:focus {
  color: #707070;
  border: solid 2px #fd9c18 !important;
  outline: none;
  box-shadow: none;
}
