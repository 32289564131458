.icon-ok-bloc {
  height: 120px;
}

.icon-ok-bloc .icon-ok {
  width: 60px;
}

.congratulation-text {
  font-family: Nunito;
  font-size: 30px;
  color: #414141;
}

.message-text {
  font-family: Nunito;
  font-size: 17px;
  color: #414141;
}

.second-bloc {
  height: 230px;
}

.btn-ok {
  background-color: #5cad20 !important;
  border-color: #5cad20 !important;
}

.modal-content {
  border-radius: 30px;
}
