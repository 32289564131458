.login-bottom-width {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6a801;
  padding: 4px;
}

.txt-title {
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  color: #414141;
}

.checkout-title {
  font-family: Nunito;
  font-size: 17px;
  color: #3f3d3d;
  margin: 6px 0 !important;
  display: flex;
  align-items: center;
}

.checkout-value {
  font-family: Nunito;
  font-size: 14px;
  color: #3f3d3d;
  font-weight: bold;
  text-align: justify;
  text-justify: inter-word;
  text-align: end;
  margin: 5px 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkout-separator::before {
  content: '';
  display: block;
  border-bottom: 2px dashed rgba(63, 61, 61, 0.3);
  margin: 5px 0 !important;
}
