.topbar {
  height: 50px;
}
.topbar .title {
  font-family: Nunito-Bold;
  font-size: 14px;
  font-weight: bold;
  color: #414141;
  margin-left: 5px;
}
