.bloc-logo {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #225828;
  height: 115px;
  margin-bottom: 9px;
  padding: 10px 0  !important;
}

.bloc-logo > img {
  width: 115px;
  height: 100%;
}
