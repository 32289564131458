.modal-content {
  border-radius: 20px !important;
}

.popup-confirm {
  font-family: Nunito;
}


.popup-confirm .input:focus,
.popup-confirm .input:active {
  border: solid 1px #707070 !important;
  outline-color: inherit !important;
  outline: inherit !important;
}

.popup-confirm .popup-confirm-text-title {
  text-align: center;
  font-family: Nunito;
  font-size: 27px;
  font-weight: bold;
  color: #414141;
  width: 80%;
}

.container-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.popup-confirm-text-sub-title {
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  color: #414141;
}

.popup-confirm .message {
  font-size: 14px;
  font-family: Nunito;
  margin-bottom: 15px;
}


:-moz-focusring {
  outline: none;
}

