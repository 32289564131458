.ant-select-selector {
  height: 52px !important;
  border-radius: 10px !important;
  border: solid 1px #707070 !important;
  padding: 9px 15px !important;
  font-size: 15px !important;
  font-family: Nunito;
  color: #707070 !important;
}

.ant-select-selection-search-input {
  height: 52px !important;
  color: #707070;
}

.ant-select-selection-item {
  padding-right: 0 !important;
}

.icon-select {
  height: 24px !important;
}

.ant-select-arrow {
  top: 41% !important;
  right: 18px !important;
}

.error-message {
  font-size: 14px;
  color: #ff0000;
  font-family: Nunito-Regular;
}

.error-border {
  border: solid 1px #ff0000 !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif;
  line-height: 1 !important;
  content: '*';
}
