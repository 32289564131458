.modal-content {
  border-radius: 20px !important;
}

.popup-otp {
  font-family: Nunito;
}

.popup-otp .input {
  border-radius: 10px;
  height: 75px;
  width: 60px !important;
  border-radius: 10px !important;
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 24px;
  font-family: Nunito;
}

.popup-otp .input:focus,
.popup-otp .input:active {
  border: solid 1px #707070 !important;
  outline-color: inherit !important;
  outline: inherit !important;
}

.popup-otp .popup-otp-text-title {
  text-align: center;
  font-family: Nunito;
  font-size: 30px;
  font-weight: bold;
  color: #414141;
  width: 80%;
}

.container-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.popup-otp-text-sub-title {
  text-align: center;
  font-family: Nunito;
  font-size: 25px;
  color: #414141;
}

.popup-otp .message {
  font-size: 14px;
  font-family: Nunito;
  margin-bottom: 15px;
}

.popup-otp .resend-verification-code {
  font-size: 14px;
  font-family: Nunito;
  margin-bottom: 10px;
  color: #0374c7;
}

:-moz-focusring {
  outline: none;
}
.text-error {
  margin: 0 0 0 8px;
  font-family: Nunito-Bold;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #ff0000;
}
